import React from 'react';
// import "./Header.css";
import Container from '@mui/material/Container';
import background from "./assets/university.gif";

// ,
//         top: 0,
//         left: 0

function Top() {

    return (
        <div className="top" 
        style={{backgroundColor: "black", 
        width:"100%", 
        position: "relative",
        marginTop: 30,
        }}>

        <Container component="main"
          
          sx={{
            height: '60vh',
            width: '100%',
            backgroundImage: `url(${background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: "center"
          }}
        />
            
            {/* <IconButton onClick={() => console.log("clicked button")}>
                <ArrowBackIosIcon className="header__icon" fontSize="large"/>
            </IconButton>

            <IconButton>
                <PersonIcon className="header__icon" fontSize="large"/>
            </IconButton> */}
        
        </div>
    );
}

export default Top;